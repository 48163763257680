.landing-how-helps{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 10%;
}

.landing-how-helps h4{
    font-size: 35px;
    font-weight: 700;
    margin: 4rem 0px;
}

.landing-how-helps-cards{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.card{
    width: 24%;
    height: fit-content;
    min-height: 50svh;
    display: flex;
    flex-direction: column;
    padding: 15px;
    justify-content: center;
    border: 1px solid #c8d6e5;
    border-radius: 5px;
}

.card div{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0px;
    text-align: center;
}

.card p{
    display: flex;
    justify-content: center;
    align-items: start;
    color: #566474;
    font-weight: 500;
    text-align: justify;
    margin: 20px 0px;
    height: 20svh;
}

.landing-how-helps-btn{
    background-color: #19A741;
    border-radius: 5px;
    border: 2px solid #19A741;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    cursor: pointer;
    margin: 40px 0px;
}

.landing-how-helps-btn:hover{
    background-color: #26c453;
    border: 2px solid #26c453;
}

@media (max-width: 820px) {
    .landing-how-helps-cards{
        flex-direction: column;
        align-items: center;
    }
    .card{
        width: 50%;
        margin-top: 30px;
        height: 20vh;
    }
}

@media (max-width: 540px) {
    .card{
        height: 25vh;
        width: 85%;
    }
    .landing-how-helps h4{
        font-size: 30px;
        text-align: center;
    }
}