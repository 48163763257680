.landing-faqs{
    display: flex;
    flex-direction: column;
    margin: 10% 10% 15% 10%;
}

.landing-faqs h4{
    font-size: 35px;
    font-weight: 700;
    text-align: center;
}

.faq-blocks{
    border: 2px solid #7dffa2;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 10px 0px;
}

.question{
    font-size: medium;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.answer{
    font-size: 15px;
    display: flex;
    justify-content: start;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #566474;
}

.rotate-90{
    rotate: 90deg;
}