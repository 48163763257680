.landing-why-join-us{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 5%;
    margin-bottom: 5rem;
}
.landing-why-join-us h4{
    font-size: 35px;
    font-weight: 700;
    margin: 4rem 0px;
}
.landing-why-join-us-main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.landing-why-join-us-main-img{
    width: 50%;
}
.landing-why-join-us-main-cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}
.landing-why-join-us-main-cards-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.landing-why-join-us-main-cards .landing-why-join-us-main-cards-row .why-join-us-points{
    display: flex;
    flex-direction: column;
    width: 47%;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #6fff98;
    margin: 0.5rem;
    border-radius: 5px;
    min-height: 28svh;
}
.landing-why-join-us-main-cards .why-join-us-points h6{
    font-size: larger;
}
.landing-why-join-us-main-cards .why-join-us-points p{
    text-align: justify;
    margin: 1rem 0rem;
}

@media (max-width: 820px){
    .landing-why-join-us-main {
        flex-direction: column;
    }
    .landing-why-join-us-main-img{
        width: 90%;
    }
    .landing-why-join-us-main-cards{
        width: 95%;
    }
    .landing-why-join-us-main-cards-row{
        flex-direction: column;
    }
    .landing-why-join-us-main-cards .landing-why-join-us-main-cards-row .why-join-us-points{
        width: 90%;
        margin: 1.2rem 0rem;
    }
}