.landing-navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 10%;
    align-items: center;
}

.landing-navbar-left {
    display: flex;
    align-items: center;
    width: 25%;
}

.landing-navbar-left-logo {
    font-size: x-large;
    padding-bottom: 5px;
}

.landing-navbar-left-explore {
    font-size: large;
    cursor: pointer;
}

.landing-navbar-left-explore p {
    padding-bottom: 5px;
    position: relative;
    color: black;
}

.landing-navbar-left-explore p::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.landing-navbar-left-explore p:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.landing-navbar-right {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.landing-navbar-right-btn-sec {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.landing-navbar-right-btn-login,
.landing-hamberger-menu-btn-login {
    padding: 10px 10px;
    width: 40%;
    background-color: white;
    border: 2px solid #19A741;
    border-radius: 5px;
    font-size: 14px;
    color: #19A741;
    cursor: pointer;
}

.landing-navbar-right-btn-signup,
.landing-hamberger-menu-btn-signup {
    padding: 10px 10px;
    width: 40%;
    background-color: #19A741;
    border: 2px solid #19A741;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    margin-left: 5%;
}

@media (max-width: 820px) {
    
}

@media (max-width: 540px) {
    .accessLogin{
        width: 100% !important;
        flex-direction: column;
    }
    .access-account {
        width: 100% !important;
        height: 55vh !important;
        padding: 4rem 2rem 0 2rem !important;
    }
}