@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.activity-body * {
	box-sizing: border-box;
}

.activity-body {
	background: #f6f5f7 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	flex-direction: column !important;
	font-family: 'Montserrat', sans-serif !important;
	height: 100vh !important;
}

.activity-body h2 {
	font-weight: 600;
	margin: 0;
	font-size: 28px;
}

.activity-body h2 {
	text-align: center;
}

.activity-body span {
	font-size: 12px;
}

.activity-body a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.activity-body button {
	border-radius: 8px;
	border: 1px solid #38dfae;
	background-color: #38dfae;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 10px 45px;
	letter-spacing: 1px;
	transition: transform 80ms ease-in;
    width: 100%;
	cursor: pointer;
}

.activity-body button:active {
	transform: scale(0.95);
}

.activity-body button:focus {
	outline: none;
}

.activity-body button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.activity-body form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 90%;
	text-align: center;
}

.activity-body input {
	background-color: #f8fafb;
	border: 1px solid #e6fff5;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
    border-radius: 5px;
}

.container {
	background-color: #fff !important;
	border-radius: 10px !important;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22) !important;
	position: relative !important;
	overflow: hidden !important;
	width: 900px !important;
	max-width: 100% !important;
	min-height: 680px !important;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #38dfae;
	background: -webkit-linear-gradient(to right, #38dfae, #38dfae);
	background: linear-gradient(to right, #38dfae, #38dfae);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
    width: 100%;
}

.activity-signin-google-btn{
    border-radius: 8px;
    width: 100%;
    background-color: #e5f9f2;
    border: 2px solid #e5f9f2;
    color: #333;
    text-transform: none;
	padding: 7px 45px !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.activity-signin-google-btn img{
    width: 8%;
}

.activity-donthaveaccnt{
	font-size: smaller;
	font-weight: 500;
	margin-top: 0.5rem;
}

.activity-donthaveaccnt a{
    color: rgb(0, 129, 204);
}

.activity-logo{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	font-size: large !important;
}

.activity-logo img{
	width: 25%;
}

.carousel.slide{
	width: 100%;
}

.carousel-indicators{
	margin-bottom: -4rem !important;
}

.carousel-indicators [data-bs-target]{
	border-radius: 100% !important;
	height: 10px !important;
	width: 10px !important;
	margin-right: 10px !important;
	margin-left: 10px !important;
}

.carousel-item img{
	width: 80% !important;
}

.carousel-control-next{
	justify-content: end !important;
}

.carousel-control-prev{
	justify-content: start !important;
}

.login-signup-error-msg{
	font-size: 14px;
	color: rgb(255, 35, 35);
	text-align: left;
	width: 100%;
	padding: 0px 5px;
}

.btn-disabled{
	background-color: #87ffdb !important;
	border-color: #87ffdb !important;
	cursor: not-allowed !important;
}

.login-signup-password-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.login-signup-password-div svg{
	position: absolute;
	right: 60px;
}

.login-signup-btn-spinner{
    margin: 0px 10px;
}

@media (max-width: 540px) {
    .overlay-container{
		display: none;
	}
	.sign-in-container{
		width: 100%;
	}
	.sign-up-container{
		width: 100%;
	}
	.container{
		width: 90% !important;
	}
	.container.right-panel-active{
		transform: translateX(0) !important;
	}
	.container.right-panel-active .sign-up-container{
		transform: translate(0) !important;
	}
}