.product-list {
  padding: 20px 100px;
  margin-top: 5rem;
  display: flex;
  justify-items: center;
}

.product-search-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 20px;
  margin-top: 3rem;
}

.product-search-filter-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-search-filter-search-box {
  width: 40%;
  border-radius: 25px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  height: 40px;
  outline: transparent;
  padding: 5px 15px;
}

.product-search-filter-search-box:focus {
  background-color: #ececec;
  border: 1px solid #ececec;
}

.product-search-filter-category-filter {
  width: 20%;
}

.product-search-filter-category-filter .clickable {
  width: 100%;
  border-radius: 25px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  height: 40px;
  outline: transparent;
  display: flex;
  padding: 2px 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.product-search-filter-category-filter .categories {
  width: 17.5%;
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 10px;
  position: absolute;
  top: 11rem;
  align-items: center;
  max-height: 20vh;
  overflow-y: scroll;
}

.product-search-filter-category-filter .categories::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.product-search-filter-category-filter .categories::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(231, 231, 231);
}

.product-search-filter-category-filter .categories li {
  width: 100%;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-search-filter-category-filter .categories li:hover {
  background-color: #f4f4f4;
}

.product-search-filter-sortings {
  display: flex;
  justify-content: space-between;
  width: 25%;
  align-items: center;
}

.product-search-filter-sortings span {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-search-filter-sortings span svg{
    margin: 0px 10px;
}

.modal-footer button {
  margin: 0px 5px;
  border: 2px solid #d63384;
  border-radius: 10px;
  background-color: #d63384;
  color: white;
  padding: 5px 10px;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background-color: transparent;
  margin: 15px 0px;

  &:focus {
    outline-color: #f8b195;
  }
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 3px;
  background: rgb(246, 114, 128);
  background: -webkit-linear-gradient(
    left,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#f67280",
      endColorstr="#355c7d",
      GradientType=1
    );
}

input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  height: 3px;
  background: rgb(246, 114, 128);
  background: -moz-linear-gradient(
    left,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#f67280",
      endColorstr="#355c7d",
      GradientType=1
    );
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 3px;
  background: rgb(246, 114, 128);
  background: -moz-linear-gradient(
    left,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#f67280",
      endColorstr="#355c7d",
      GradientType=1
    );
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #f8b195;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 8px;
  background: #222
    url("http://codemenatalie.com/wp-content/uploads/2019/09/slider-thumb.png")
    center no-repeat;
  background-size: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #f8b195;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 8px;
  background: #222
    url("http://codemenatalie.com/wp-content/uploads/2019/09/slider-thumb.png")
    center no-repeat;
  background-size: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #f8b195;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 8px;
  background: #222
    url("http://codemenatalie.com/wp-content/uploads/2019/09/slider-thumb.png")
    center no-repeat;
  background-size: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
