.landing-navbar-font{
    font-family: "Noto Sans", sans-serif;
}

.landing-hero{
    display: flex;
    padding: 0px 10%;
}

.landing-hero-left{
    width: 50%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-hero-left-heading{
    font-size: 45px;
    font-weight: 600;
}

.green{
    color: #48A539;
}

.landing-hero-left-des{
    width: 70%;
    color: #566474;
    font-weight: 600;
    margin-top: 25px;
}

.landing-hero-btn{
    border: 2px solid #19A741;
    background-color: #19A741;
    color: white;
    width: 20%;
    padding: 10px 5px;
    font-size: medium;
    margin: 20px 0px;
    cursor: pointer;
    border-radius: 5px;
}

.landing-hero-btn:hover{
    background-color: #26c453;
    border: 2px solid #26c453;
}

@media (max-width: 820px) {
    .landing-hero{
        flex-direction: column;
    }
    .landing-hero-left{
        width: 100%;
        height: 50vh;
    }
}

@media (max-width: 540px) {
    .landing-hero{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 13%;
    }
    .landing-hero-left{
        width: 100%;
        height: 50vh;
        align-items: center;
    }
    .landing-hero-btn{
        width: 100%;
    }
    .landing-hero-left-heading{
        font-size: 35px;
    }
    .landing-hero-left-des{
        width: 95%;
    }
}