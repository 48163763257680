.landing-navbar-font{
    font-family: "Noto Sans", sans-serif;
}
.landing-navbar{
    display: flex;
    justify-content: space-between;
    padding: 10px 10%;
    align-items: center;
}

.landing-navbar-left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
}

.landing-navbar-left-logo{
    width: 70%;
}

.landing-navbar-left-logo img{
    width: 70%;
}

.landing-navbar-left-explore{
    font-size: large;
    cursor: pointer;
}
.landing-navbar-left-explore p{
    padding-bottom: 5px;
    display: inline-block;
    position: relative;
    color: black;
    margin-bottom: 0rem;
}
.landing-navbar-left-explore p::after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.landing-navbar-left-explore p:hover::after{
    transform: scaleX(1);
    transform-origin: bottom left;
}

.landing-navbar-right{
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.landing-navbar-right-btn-sec{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.landing-navbar-right-btn-login{
    padding: 10px 10px;
    width: 40%;
    background-color: white;
    border: 2px solid #38802C;
    border-radius: 5px;
    font-size: 14px;
    color: #38802C;
    cursor: pointer;
}
.landing-navbar-right-btn-signup{
    padding: 10px 10px;
    width: 40%;
    background-color: #38802C;
    border: 2px solid #38802C;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    margin-left: 5%;
}
.landing-navbar-right-btn-signup:hover{
    padding: 10px 10px;
    width: 40%;
    background-color: #26c453;
    border: 2px solid #26c453;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.landing-navbar-right-hammenu{
    display: none;
}

.landing-hamberger-menu{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    width: 100%;
    padding-bottom: 25px;
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.landing-hamberger-menu-top{
    width: 84%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.landing-hamberger-menu-btns{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}

.landing-hamberger-menu-btn-login{
    width: 100%;
    background-color: white;
    color: #38802C;
    border: 2px solid #38802C;
    font-size: medium;
    padding: 10px 5px;
    margin: 10px 0px;
}

.landing-hamberger-menu-btn-signup{
    width: 100%;
    background-color: #38802C;
    color: white;
    border: 2px solid #38802C;
    font-size: medium;
    padding: 10px 5px;
    margin: 10px 0px;
}

.landing-hamberger-menu-btn-more{
    width: 100%;
    background-color: white;
    color: #38802C;
    border: 2px solid #38802C;
    font-size: medium;
    padding: 10px 5px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropbtn{
    margin: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
}

.dropbtn{
    cursor: pointer;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
}


.dropdown-content a:hover {background-color: #c8d6e5;}


.dropdown:hover .dropdown-content {display: block;}


.landing-hamberger-menu-more-expanded{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: start;
}

.landing-hamberger-menu-more-expanded a{
    width: 100%;
    padding: 10px 5px;
    margin-top: 5px;
    text-decoration: none;
}

.rotate-90{
    rotate: 180deg;
}

@media (max-width: 820px) {
    .landing-navbar{
        padding: 10px 8%;
    }
    .landing-navbar-left-explore{
        display: none;
    }
    .landing-navbar-left{
        width: 80%;
    }
    .landing-navbar-right{
        justify-content: right;
    }
    .landing-navbar-right-btn-login{
        display: none;
    }
    .landing-navbar-right-btn-signup{
        display: none;
    }
    .landing-navbar-right-hammenu{
        display: block;
    }
    .landing-navbar-left-logo{
        width: 100%;
    }
    
    .landing-navbar-left-logo img{
        width: 90%;
    }
    
}

@media (max-width: 540px) {
    .landing-navbar-left-explore{
        display: none;
    }
    .landing-navbar-left{
        width: 80%;
    }
    .landing-navbar-left-logo{
        width: 100%;
    }
    
    .landing-navbar-left-logo img{
        width: 50%;
    }
    .landing-navbar-left{
        width: 55% !important;
    }
    .dropdown{
        display: none;
    }
    .landing-hamberger-menu-btn-signup{
        margin-left: 0 !important;
    }
}