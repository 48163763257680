.about{
  justify-content: center;
  text-align: center;
  margin-bottom: 8rem;
}
.about_head1{
  font-size: 36px;
  line-height: 1.3;
  color: #131414;
  font-weight: 450;
}
.about_head2{
  font-size: 36px;
  line-height: 1.3;
  color: #19A741;
  font-weight: 450;
}
.about_content{
  text-align: left;
  margin: 2rem 8rem;
  font-size: 18px;
  color: #abb8c3;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_content p{
  text-align: justify;
  width: 80%;
}

@media (max-width: 820px){
  .about{
    margin-top: 8rem;
    padding: 2px 20px;
  }
  .about_head2{
    font-size: 25px;
  }
  .about_head1{
    font-size: 25px;
  }
  .about_content{
    margin: 2rem 0.5rem;
  }
  .about_content p{
    width: 80%;
  }
}